<template>
  <div class="inner-section">
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">
            {{ $t('elearning_tim.batch_assign') }}
        </h4>
      </template>
      <template v-slot:headerAction>
        <router-link class="btn-add" to="batch-assign">{{ $t('elearning_tim.batch_assign') }} {{ $t('globalTrans.list') }}</router-link>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="6" sm="6">
                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="6">
                <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="circular_memo_no"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.circular_memo_no"
                        :options="circularList"
                        id="circular_memo_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-overlay :show="load">
              <b-row>
                <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="formData.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Batch" vid="batch_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="batch_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.batch_id"
                        :options="batchList"
                        @change="getAllData()"
                        id="batch_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Assign For" vid="assign_for" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="assign_for"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_tim.assign_for')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.assign_for"
                        :options="userTypeList"
                        id="assign_for"
                        @change="getAllData()"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <br/>
              <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('elearning_tim.trainer_trainee_application')}} {{$t('globalTrans.list')}}</h4>
                </template>
                <template v-slot:body>
                  <b-table-simple class="mb-0" hover small caption-top responsive bordered>
                    <b-tr>
                      <b-th class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                      <b-th>{{$t('globalTrans.name')}}</b-th>
                      <b-th>{{$t('globalTrans.mobile')}}</b-th>
                      <b-th>{{$t('globalTrans.designation')}}</b-th>
                      <b-th>{{$t('elearning_config.batch')}}</b-th>
                      <b-th>{{$t('globalTrans.select')}}</b-th>
                    </b-tr>
                    <b-tr v-for="(train,index) in formData.details" :key="index">
                      <b-td class="text-center">{{$n(index+1)}}</b-td>
                      <b-td>
                        {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                      </b-td>
                      <b-td>
                        {{ train.mobile }}
                      </b-td>
                      <b-td>
                        {{ ($i18n.locale==='bn') ? train.designation_bn : train.designation_en }}
                      </b-td>
                      <b-td>
                        {{ ($i18n.locale==='bn') ? train.batch_bn : train.batch }}
                      </b-td>
                      <b-td>
                        <b-col>
                          <b-form-checkbox
                            :id="'checkbox-' + train.id"
                            v-model="train.select"
                            :name="'checkbox-' + train.id"
                            :value=1
                            unchecked-value=0
                          >
                          </b-form-checkbox>
                        </b-col>
                      </b-td>
                    </b-tr>
                    <template v-if="formData.details.length === 0">
                      <tr>
                        <th colspan="6" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                      </tr>
                    </template>
                  </b-table-simple>
                </template>
              </body-card>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                  <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                  <router-link to="batch-assign" class="mr-1 btn btn-danger btn-sm">{{ $t('globalTrans.cancel') }}</router-link>
                </div>
              </div>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </template>
    </body-card>
  </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { applicationForBatchList, trainerSortingBatchStore, circularPublication, circularPublicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import flatpickr from 'flatpickr'

export default {
    mixins: [ModalBaseMasterList],
    data () {
      return {
        trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        search: {
          org_id: 0,
          batch_id: 0,
          mobile: ''
        },
        formData: {
          circular_memo_no: 0,
          fiscal_year_id: 0,
          org_id: 0,
          batch_id: 0,
          assign_for: 0,
          training_type_id: 0,
          training_title_id: 0,
          // sort: 2,
          details: []
        },
        officeTypeList: [],
        officeList: [],
        trainingCategoryList: [],
        trainingTitleList: [],
        load: false,
        // loading: false,
        detailsErrors: [],
        batchList: [],
        circularList: [],
        circularLoading: false,
        circularErrorMsg: false,
        trainerProfileError: ''
      }
    },
    created () {
      this.getCircularList()
      this.formData = Object.assign({}, this.formData, {
        fiscal_year_id: this.$store.state.currentFiscalYearId
      })
    },
    computed: {
      userTypeList () {
        const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      }
    },
    watch: {
      'formData.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'formData.circular_memo_no': function (newValue) {
        this.getCircularMemoNo(newValue)
      },
      'formData.training_type_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'formData.org_id': function (newValue) {
        this.batchList = this.getBatchList(newValue)
      }
    },
    mounted () {
      flatpickr('.fromDate', {})
    },
    methods: {
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.formData.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      async getCircularMemoNo (getCircularMemoNo) {
        if (getCircularMemoNo) {
          this.load = true
          const params = {
            circular_memo_no: getCircularMemoNo,
            table: 'iab_circular_publications'
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
          if (!result.success) {
            this.formData.fiscal_year_id = 0
            this.formData.org_id = 0
            this.formData.training_title_id = 0
            this.formData.training_type_id = 0
            // this.formData.sort = 2
            this.formData.details = []
            this.circularErrorMsg = 'Please Create Circular'
            this.load = false
          } else {
            this.circularErrorMsg = false
            const trData = result.data
            this.formData.fiscal_year_id = trData.fiscal_year_id
            this.formData.org_id = trData.org_id
            this.formData.training_title_id = trData.training_title_id
            this.formData.training_type_id = trData.training_type_id
            this.load = false
          }
        }
      },
      getAllData () {
        this.load = true
        if (this.formData.assign_for && this.formData.batch_id) {
          this.loadData()
        } else {
          this.formData.details = []
          this.load = false
        }
      },
      async loadData () {
        const searchData = this.formData
        searchData.details = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, applicationForBatchList, searchData)
        if (result.success) {
          const datas = result.data.map(item => {
            const select = {}
            if (this.formData.batch_id === item.batch_id) {
              select.select = 1
            } else {
              select.select = 0
            }
            const batchObj = this.$store.state.TrainingElearning.commonObj.batchList.find(doc => doc.value === parseInt(item.batch_id))
            const batchData = {}
            if (typeof batchObj !== 'undefined') {
              batchData.batch = batchObj.text_en
              batchData.batch_bn = batchObj.text_bn
            } else {
              batchData.batch = ''
              batchData.batch_bn = ''
            }
            return Object.assign({}, item, select, batchData)
          })
          this.formData.details = datas
          this.load = false
        } else {
          this.formData.details = []
          this.load = false
        }
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_type_id === categoryId)
        }
        return trainingTitleList
      },
      getBatchList (orgId) {
        const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
        if (orgId) {
          return batchList.filter(item => item.org_id === orgId)
        }
        return batchList
      },
      async createData () {
        this.load = true
        let result = null
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainerSortingBatchStore, this.formData)
        if (result.success) {
          // this.$socket.emit('send-notification', result.notification)
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$refs.form.reset()
          this.$router.push('/training-e-learning-service/tim/batch-assign')
        }
        this.load = false
      }
    },
    beforeDestroy () {
      clearInterval(this.inputTimer)
    }
}
</script>
